<template>
    <div class="fixed top-0 left-0 w-full h-full bg-[#F5F6F6]" style="z-index: 50">
        <div v-if="isLoading" class="fixed top-100 left-0 w-full h-full flex items-center">
            <LoadingComponent />
        </div>
        <div v-else class="text-left">
            <div class="fixed top-0 left-0 w-full h-[8%] bg-[#fff] px-[15%] flex justify-between items-center">
                <!-- <img loading="lazy" decoding="async" @click="this.$router.go()" src="@/assets/Images/go_logo.svg" alt="Recruitable" width="150" height="40" class="thelogo" /> -->
                <p class="text-2xl font-bold">
                    {{ assessmentSamples[selectedAssessment].assessmentName }}
                </p>
                <div v-if="!changeAssess">
                    <button class="nextStep flex items-center justify-center" @click="nextStep" v-if="!finish">
                        Next
                        <font-awesome-icon :icon="['fas', 'arrow-right-long']" class="mx-3" />
                    </button>
                    <button class="nextStep flex items-center justify-center" v-else @click="closePreviewWindow">Finish</button>
                </div>
            </div>
            <div class="fixed top-[9%] left-0 w-full h-full flex justify-center">
                <div v-if="changeAssess" class="w-[40%] h-[fit-content] text-left bg-[#fff] my-[3%] rounded p-[3%] gap-[50px]">
                    <p class="text-2xl font-bold">
                        {{ assessmentSamples[selectedAssessment].assessmentName }}
                    </p>
                    <p class="text-[14px] font-md my-10">
                        {{ assessmentSamples[selectedAssessment].description }}
                    </p>
                    <div class="w-full flex justify-end">
                        <button class="nextStep flex items-center justify-center text-2xl font-bold" @click="() => (this.changeAssess = false)">Start</button>
                    </div>
                </div>
                <div v-else class="w-[70%] h-[fit-content] bg-[#fff] my-[3%] rounded p-[3%] gap-[50px]" style="display: grid; grid-template-columns: 1fr 1fr">
                    <div>
                        <h2>{{ questions[step - 1].question }}</h2>
                        <!-- <prism autodetect> function hello(){ console.log(Hello World) } </prism> -->

                        <div class="code-container" v-if="questions[this.step - 1]?.code">
                            <!-- Display code using <pre> tag -->
                            <pre v-highlightjs="this.questions[this.step - 1]?.code" class="code-block"><code class="language-javascript" ></code></pre>
                        </div>
                        <br />
                        <span v-if="!questions[step - 1].description?.toLowerCase().includes('png')">{{ questions[step - 1].description }}</span>
                        <img
                            loading="lazy"
                            decoding="async"
                            v-else
                            :src="`https://server.go-platform.com/${questions[step - 1].description}`"
                            style="width: 700px; height: fit-content; margin: 0 auto"
                            alt=""
                        />
                    </div>
                    <div>
                        <div
                            v-for="(option, index) in currentOptions"
                            :key="index"
                            :class="{ 'radio-container': true, checked: selectedOption === option }"
                            @click="selectRadio(option)"
                            class="relative"
                        >
                            <input type="radio" :id="option" name="option" :value="option" v-model="selectedOption" class="custom-radio" />
                            <label :for="option" class="mx-5">{{ option }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoadingComponent from "@/components/LoadingComponent.vue";
// import "prismjs";
// import "prismjs/themes/prism-okaidia.css";
// import Prism from "vue-prism-component";

export default {
    name: "PreviewProject",
    components: {
        LoadingComponent,
        // Prism
    },
    data() {
        return {
            isLoading: true,
            projectId: "",
            step: 1,
            selectedOption: null,
            questions: [],
            assessmentSamples: [],
            selectedAssessment: 0,
            finish: false,
            changeAssess: true,
        };
    },
    computed: {
        currentOptions() {
            return this.questions[this.step - 1].options;
        },
        formattedCode() {
            // Here you can apply any formatting or syntax highlighting to the code
            // For simplicity, let's just replace newline characters with <br> tags
            return this.questions[this.step - 1]?.code.replace(/\n/g, "<br>");
        },
    },
    watch: {
        selectedAssessment: function () {
            this.changeAssess = true;
        },
    },
    methods: {
        nextStep() {
            this.step++;
            this.selectedOption = null;
            if (this.assessmentSamples.length === this.selectedAssessment + 1 && this.questions.length == this.step) {
                this.finish = true;
                return;
            }
            if (this.questions.length + 1 === this.step) {
                this.selectedAssessment++;
                this.questions = this.assessmentSamples[this.selectedAssessment].questions;
                this.step = 1;
            }
        },
        selectRadio(optionValue) {
            // When an option is clicked, update the selectedOption value
            this.selectedOption = optionValue;
        },
        closePreviewWindow() {
            // Close the preview window if it exists
            alert("You can't submit the results as you're in preview mode");
            window.close();
        },
    },
    mounted() {
        this.projectId = this.$route.query.id;

        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://server.go-platform.com/projects/preview?id=${this.projectId}`,
            headers: {},
        };

        axios
            .request(config)
            .then((response) => {
                this.assessmentSamples = response.data.questions;
                this.questions = this.assessmentSamples[0].questions;
                this.selectedAssessment = 0;
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style scoped lang="scss">
.nextStep {
    width: 120px;
    height: 50px;
    color: white;
    font-weight: 600;
    background-color: #2196f3;
    border: 1px solid #2196f3;
    border-radius: 5px;
    font-size: 16px;
}

.loader {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-container {
    margin-top: 5%;
    margin-bottom: 10px;
    /* Adjust margin as needed */
    cursor: pointer;
    /* Change cursor to pointer */
    border: 1px solid #ccc;
    /* Border color */
    padding: 16px 16px;
    /* Adjust padding as needed */
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
.radio-container:hover {
    background-color: #f0f0f0;
    /* Background color on hover */
}

/* Style for checked radio button container */
.checked {
    background-color: #2196f3;
    /* Background color when checked */
    color: #fff;
    /* Text color when checked */
    border-color: #2196f3;

    /* Border color when checked */
    &:hover {
        background-color: #2196f3;
        /* Background color when checked */
    }
}

.custom-radio {
    /* Hide the default radio button */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Create a custom circular radio button */
    width: 25px;
    height: 25px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    /* Position the radio button relative to its container */
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.custom-radio:checked {
    /* Change border color when checked */
    border-color: #fff;

    /* Add inner circle when checked */
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
    }
}

.code-container {
    background-color: #1e1e1e;
    /* VSCode dark background color */
    color: #d4d4d4;
    /* VSCode text color */
    padding: 20px;
    border-radius: 5px;
}

.code-block {
    font-family: "Fira Code", monospace;
    /* Use a monospace font for code */
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    overflow-x: auto;
    /* Allow horizontal scrolling for long lines */
    white-space: pre-wrap;
    /* Preserve whitespace and line breaks */
}

/* Syntax highlighting */
.code-block .keyword {
    color: #569cd6;
}

/* VSCode keyword color */
.code-block .string {
    color: #ce9178;
}

/* VSCode string color */
.code-block .comment {
    color: #6a9955;
}

/* VSCode comment color */
.code-block .function {
    color: #dcdcaa;
}

/* VSCode function color */
</style>
