<template>
    <div class="library-container relative bg-white rounded-md">
        <AssessementPreview :assessment="preview" :showPreview="showPreview" :togglePreview="togglePreview" :addAssessment="addAssessment" />
        <ConfirmAssessments
            :score="score"
            :yourAssessment="yourAssessment"
            :toggleshowPreviewWindow="toggleshowPreviewWindow"
            :updateAssessments="updateProjectAssessments"
            :showPreviewWindow="showPreviewWindow"
            :deleteAssessement="deleteAssessement"
            :project_id="project_id"
        />
        <ToastNotification :message="this.message ? this.message : `Assessment updated successfully`" :isVisible="isVisible" :bgColor="'success'" />
        <div class="w-full flex justify-between items-center my-2 mx-2 mb-[2%]">
            <div class="flex items-center space-x-3">
                <!-- <img src="@/assets/Images/go_logo-copy.svg" alt="Logo" class="h-10" /> -->
                <h1 class="text-3xl font-bold tracking-widest">{{ $t("Assessment Library") }}</h1>
                <font-awesome-icon :icon="['far', 'bookmark']" class="text-2xl text-NeonBlue" />
            </div>
            <Popper :content="$t('Create a new project for assessment')" placement="top" :hover="true">
                <div
                    @click="
                        () => {
                            this.$router.push('/newAssessment');
                        }
                    "
                    class="bg-[#2196f3] text-white inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-semibold flex items-center justify-center relative cursor-pointer statusCard shadow-card hover:shadow-none"
                >
                    New project
                </div>
            </Popper>
        </div>
        <hr class="h-[5px] bg-[#2196f333] w-full mt-2 mb-2" />

        <div class="libraryWrapper flex flex-col mt-4 justify-start lg:grid relative">
            <div class="w-full h-fit md:w-72 bg-gradient-to-r from-NeonBlue to-[#c084fc] text-white p-6 space-y-6 shadow-lg transition-all duration-300 sticky top-20">
                <!-- Header -->

                <!-- Search Input -->
                <div class="relative group">
                    <input
                        v-model="searchText"
                        type="text"
                        placeholder="Search..."
                        class="w-full py-3 pl-10 pr-4 bg-white/20 text-white rounded-lg shadow-md outline-none placeholder:text-white/60 placeholder:text-lg placeholder:font-bold focus:ring-2 focus:ring-white/50 transition-all duration-300"
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute left-3 top-2.5 h-5 w-7 text-white/60 group-hover:text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="3"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-4.35-4.35M10 17a7 7 0 110-14 7 7 0 010 14z" />
                    </svg>
                </div>

                <!-- Filter by Category -->
                <div>
                    <h3 class="font-semibold text-lg">Category</h3>
                    <div class="mt-3 space-y-2">
                        <label v-for="(category, index) in categories" :key="index" class="flex items-center space-x-3 cursor-pointer hover:opacity-90 transition-transform transform hover:scale-105">
                            <input
                                type="checkbox"
                                :value="category"
                                v-model="selectedCategories"
                                @change="filterAssessments"
                                class="checkBox h-5 w-5 text-blue-500 rounded focus:ring-2 focus:ring-white/70"
                            />
                            <span>{{ category }}</span>
                        </label>
                    </div>
                </div>

                <!-- Filter by Duration -->
                <div>
                    <h3 class="font-semibold text-lg">Test Duration</h3>
                    <div class="mt-3 space-y-2">
                        <label
                            v-for="(duration, index) in testDurations"
                            :key="index"
                            class="flex items-center space-x-3 cursor-pointer hover:opacity-90 transition-transform transform hover:scale-105"
                        >
                            <input type="checkbox" :value="duration" v-model="selectedDurations" @change="SearchByDuration" class="h-5 w-5 text-blue-500 rounded focus:ring-2 focus:ring-white/70" />
                            <span>{{ duration }}</span>
                        </label>
                    </div>
                </div>

                <!-- Filter by Focus -->
                <div>
                    <h3 class="font-semibold text-lg">Test Focus</h3>
                    <div class="mt-3 space-y-2">
                        <label v-for="(focus, index) in testFucus" :key="index" class="flex items-center space-x-3 cursor-pointer hover:opacity-90 transition-transform transform hover:scale-105">
                            <input type="checkbox" :value="focus" v-model="selectedFocus" @change="filterAssessments" class="checkBox h-5 w-5 text-blue-500 rounded focus:ring-2 focus:ring-white/70" />
                            <span>{{ focus }}</span>
                        </label>
                    </div>
                </div>

                <!-- Clear Filters -->
                <div class="pt-4 border-t border-white/20">
                    <button
                        @click="clearFilters"
                        class="w-full py-2 text-sm font-medium bg-white/20 hover:bg-white/30 text-white rounded-lg shadow-md transition-all duration-300 transform hover:scale-105"
                    >
                        Clear Filters
                    </button>
                </div>
            </div>
            <CardsLoader v-if="allAssessments.length === 0" />
            <Transition name="slide" v-else>
                <div class="library" v-if="psychometrics?.length > 0">
                    <div class="library-grid-top flex flex-col lg:grid w-full">
                        <LibraryAssessCard v-for="(assess, index) in filteredAssessments" :key="index" :assessement="assess" @openPreview="OpenPreview(assess)" />
                    </div>
                    <div v-if="psychometrics?.length < 9" class="library-grid-top w-full relative">
                        <div class="absolute w-full h-full bg-red-500 top-0 left-0 fadedCards" @click="this.Store.toggleSubscriptions()"></div>
                        <LibraryAssessCard v-for="(assess, index) in 3" :key="index" :assessement="assess" @openPreview="OpenPreview(assess)" />
                    </div>
                </div>
            </Transition>
        </div>
        <upgradePlan v-if="!this.Store.premium" />
    </div>
</template>
<font-awesome-icon :icon="['fas', 'circle-question']" />
<script>
import AssessementPreview from "@/components/dashboard/library/AssessementPreview.vue";
import LibraryAssessCard from "@/components/dashboard/library/LibraryAssessCard.vue";
import ConfirmAssessments from "@/components/unsued/ConfirmAssessments.vue";
import ToastNotification from "@/components/ToastNotification";
import axios from "axios";
import upgradePlan from "@/components/upgradePlan.vue";
import { useStore } from "@/store/index";
import CardsLoader from "@/components/cardsLoader.vue";
// import SearchCard from "@/components/SearchCard.vue";
// import Subscriptions from "@/components/Subscriptions.vue";
export default {
    name: "LibraryView",
    components: {
        // LibraryCard,
        AssessementPreview,
        LibraryAssessCard,
        // AssessementTab,
        // AssessementTabs,
        ConfirmAssessments,
        upgradePlan,
        ToastNotification,
        CardsLoader,
        // SearchCard,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {
        projectList() {
            return this.Store.getProjectList;
        },
    },
    data() {
        return {
            // showTab: { hard: false, soft: false, psy: false, top: false },
            preview: {},
            showPreview: false,
            showPreviewWindow: false,
            isVisible: false,
            LibraryAssessCard: [],
            yourAssessment: [],
            allAssessments: [],
            searchPayload: "",
            hardSkills: [],
            personalityTests: [],
            softSkills: [],
            psychometrics: [],
            selected: [true, false],
            current: 0,
            score: 0,
            filteredAssessments: [],
            premium: false,
            imagePath: "",
            project_id: "",
            message: "",
            categories: ["Hard Skills", "Interpersonal Skills", "Behavioral Skills", "Personality"],
            selectedCategories: [],
            searchText: "",
            testDurations: ["Up to 8min", "8min to 12min", "12min to 15min"],
            selectedDurations: [],
            testFucus: ["Verbal Reasoning", "Aptitude", "Numerical Reasoning"],
            selectedFocus: [],
            companies: ["KPMG", "PWC", "Workforce", "Adobe"],
            selectedCompanies: [],
            filterSM: false,
        };
    },

    methods: {
        filterAssessments() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const mappedCategories = this.selectedCategories.map((category) => {
                    switch (category) {
                        case "Hard Skills":
                            return "Hard Skills";
                        case "Interpersonal Skills":
                            return "Soft Skills";
                        case "Behavioral Skills":
                            return "Psychometrics";
                        case "Personality":
                            return "Personality";

                        default:
                            return category;
                    }
                });
                const matchesCategory = mappedCategories.length === 0 || mappedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                const matchesCompany = this.selectedCompanies.length === 0 || this.selectedCompanies.some((company) => assessment.name.includes(company));

                return matchesSearch && matchesCategory && matchesDuration && matchesFocus && matchesCompany;
            });
        },

        searchAssessment() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                return matchesSearch && matchesCategory && matchesDuration && matchesFocus;
            });
        },
        SearchByDuration() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                return matchesSearch && matchesCategory && matchesDuration && matchesFocus;
            });
        },
        checkDuration(assessment) {
            const duration = assessment.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessment.questions_nbr * 35) / 60);
            return (
                this.selectedDurations.length === 0 ||
                this.selectedDurations.some((selectedDuration) => {
                    if (selectedDuration === "Up to 8min" && duration <= 8) {
                        return true;
                    } else if (selectedDuration === "8min to 12min" && duration > 8 && duration <= 12) {
                        return true;
                    } else if (selectedDuration === "12min to 15min" && duration > 12 && duration <= 15) {
                        return true;
                    }
                    return false;
                })
            );
        },
        transformedName() {
            // Use the same transformation logic as before
            const nameArray = this.filteredAssessments?.split(/-/);

            // Capitalize the first letter of each word and remove "-"
            const transformedArray = nameArray.map((part) => {
                const words = part.split(/\s+/);
                return words
                    .map((word) => {
                        const capitalWords = ["KPMG", "DRAGNET", "GTCO", "NNPC", "PWC", "ZENITHBANK", "xml", "aws", "vba"];

                        // Capitalize the entire word if it's in the list
                        if (capitalWords.includes(word.toUpperCase())) {
                            return word.toUpperCase();
                        }
                        // Capitalize the first letter of each word
                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                    })
                    .join(" ");
            });

            // Join the parts into a sentence
            return transformedArray.join(" ");
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        toggleshowPreviewWindow() {
            if (this.project_id) {
                this.showPreviewWindow = !this.showPreviewWindow;
            } else {
                this.message = "Please select a project first";
                this.isVisible = true;
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
        },
        deleteAssessement(assess) {
            const newArr = this.yourAssessment.filter((el) => el !== assess);
            this.yourAssessment = newArr;
        },
        addAssessment(test) {
            let testName = test.name;
            if (this.yourAssessment.length < 5) {
                let assessmentFound = this.yourAssessment.find((el) => el.name === testName);

                if (assessmentFound) {
                    alert("You already added this assessment");
                } else {
                    // console.log({ test });
                    let clonedTest = { ...test };
                    clonedTest.delete = true;
                    this.yourAssessment.push(clonedTest);
                }
                this.showPreview = false;
            } else {
                this.message = "You can add up to 5 assessments only";
                this.isVisible = true;
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
        },
        OpenPreview(test) {
            this.preview = test;
            this.togglePreview();
        },
        togglePreview() {
            this.showPreview = !this.showPreview;
        },
        clearAllAssessments() {
            this.yourAssessment = [];
        },

        updateProjectAssessments(projectid, sliderValue) {
            const ID = typeof this.id === "undefined" ? projectid : this.project_id;
            const project = this.Store.getProjectList.filter((pro) => pro.id === projectid)[0];
            if (typeof ID !== "undefined") {
                let data = JSON.stringify({
                    assessments: project ? [...this.yourAssessment] : this.yourAssessment,
                    id: ID,
                    score: parseInt(sliderValue),
                });

                let config = {
                    method: "put",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/projects/update`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };

                axios
                    .request(config)
                    .then(() => {
                        this.toggleshowPreviewWindow();
                        this.isVisible = true;
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 5000);
                        this.$router.push("/library-partial");
                        this.Store.fetchProjects();
                    })
                    .catch((error) => {
                        console.log(error);
                        alert("there was an arror");
                    });
            } else {
                alert("you need to select a project");
            }
        },
        clearFilters() {
            this.searchText = "";
            this.selectedCategories = [];
            this.selectedDurations = [];
            this.selectedFocus = [];
        },
        async fetchProjectAssessments(id) {
            if (id) {
                let config = {
                    method: "get",
                    maxBodyLength: Infinity,
                    url: "https://server.go-platform.com/projects/projectAssessments",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: id,
                    },
                    withCredentials: true,
                };

                await axios
                    .request(config)
                    .then((response) => {
                        this.yourAssessment = response.data.assessments;
                        this.score = response.data.score;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    watch: {
        async project_id(newVal) {
            await this.fetchProjectAssessments(newVal);
        },
        allAssessments: {
            handler(newAssessments) {
                this.filteredAssessments = newAssessments.filter((assessment) => {
                    return assessment.name.toLowerCase().includes(this.searchPayload.toLowerCase());
                });
                this.isLoading = false;
            },
            immediate: true, // This ensures the handler runs immediately on component mount
        },
        selectedCategories: "filterAssessments",
        searchText: "searchAssessment",
        selectedDurations: "SearchByDuration",
    },
    async mounted() {
        this.Store.fetchProjects();
        this.id = this.$route.query.id;
        this.project_id = this.$route.query.id ? this.$route.query.id : "";

        axios
            .get("https://server.go-platform.com/AssessmentTest/hardSkills", {
                withCredentials: true,
            })
            .then((res) => {
                this.hardSkills = res.data.hardSkills;
                this.Store.setPremium(res.data.premium);
                this.allAssessments = [...this.allAssessments, ...this.hardSkills];
            });
        axios
            .get("https://server.go-platform.com/AssessmentTest/psychometrics", {
                withCredentials: true,
            })
            .then((res) => {
                this.psychometrics = res.data;
                this.allAssessments = [...this.allAssessments, ...this.psychometrics];
            });
        axios
            .get("https://server.go-platform.com/AssessmentTest/softSkills", {
                withCredentials: true,
            })
            .then((res) => {
                this.softSkills = res.data;
                this.allAssessments = [...this.allAssessments, ...this.softSkills];
            });
        axios
            .get("https://server.go-platform.com/AssessmentTest/topAssessments", {
                withCredentials: true,
            })
            .then((res) => {
                this.LibraryAssessCard = res.data;
                this.allAssessments = [...this.allAssessments, ...this.LibraryAssessCard];
            });
        axios
            .get("https://server.go-platform.com/AssessmentTest/personality", {
                withCredentials: true,
            })
            .then((res) => {
                this.personalityTests = res.data;
                this.allAssessments = [...this.allAssessments, ...this.personalityTests];
            });
        axios
            .get("https://server.go-platform.com/AssessmentTest/personality", {
                withCredentials: true,
            })
            .then((res) => {
                this.personalityTests = res.data;
                this.allAssessments = [...this.allAssessments, ...this.personalityTests];
            });
    },
};
</script>

<style lang="scss" scoped>
.library-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
}

.library-grid-top {
    // display: flex;
    // flex-flow: row wrap;
    // display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    gap: 4rem;
    width: 100%;
}

.library-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: -5%;
}

.libraryWrapper {
    // display: grid;
    grid-template-columns: 1fr 3fr;
    // height: 100vh;

    // overflow: scroll;
    .library {
        // margin-left: 20%;
        padding: 1%;
        // height: 100vh;
        // overflow-y: scroll;
    }
}

.library::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.library::-webkit-scrollbar-track {
    background-color: #bbdffc;
    /* Color of the scrollbar track */
    //border-radius: 10px;
    /* Rounded corners */
    border: none;
}

.library::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    /* Color of the scrollbar thumb */
    border-radius: 5px;
    height: 40px;
    /* Rounded corners */
}

.library::-webkit-scrollbar-thumb:hover {
    background-color: #2195f328;
    /* Color of the scrollbar thumb on hover */
}

.myAssessments {
    display: flex;
    flex-direction: column;
    margin-top: 5%;

    > :first-child {
        // width: 60%;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        button {
            font-size: 16px;
            width: 30%;
            // height: 100%;
            margin-right: 1%;
            cursor: pointer;
            transition: all 0.4s ease-in-out;

            &:hover {
                transform: scale(1.04);
            }
        }

        h1 {
            width: 35%;
            margin: 0;
            padding: 0;
            padding-left: 0;
        }

        > :nth-child(3) {
            background: #fff;
            box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);
            color: #050708;
            border: 1px solid #c3cad9;
        }
    }
}

.navigation-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    border-bottom: 2px solid #edeff2;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 18px;
    color: #7d8fb3;
    //margin-right: 24px;
}

.dropdown-search {
    width: 40% !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column !important;
    position: absolute;
    height: 450px;
    background: white !important;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 0 0 30px 30px !important;
    top: 98%;
    right: 49.2%;
    transform: translateX(50%);
    z-index: 5;
    overflow-y: auto;
    box-shadow:
        0 -4px 8px rgba(0, 0, 0, 0.2),
        0 4px 8px rgba(0, 0, 0, 0.2);
    /* No shadow on top */
}

.row-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #edeff2e3;
    gap: 30px;
    width: 100%;
    height: 70px;
    padding: 15px;

    img {
        height: 20px;
        width: 20px;
        margin: 0;
    }
}

.row-search:hover {
    cursor: pointer;
    background: #f5f6f8;
}

.card-icon {
    font-size: 20px;
    color: #2196f3;
}

.nav-links img {
    margin-right: 12px;
}

h1 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    // color: #656669;
    // margin-right: 56px;
    position: relative;
    // padding-left: 01rem;
}

h2 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.26);
}

.add-assessment-button {
    background: #2196f3;
    border: none;
    border-radius: 70px;
    padding: 0.5rem 0.1rem;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    width: 15%;
    color: #fff;
    box-shadow: 0 0 2px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
        transform: scale(1.04);
    }

    &:disabled {
        background: #e5e5ef;
        color: #656669;
        box-shadow: none;
        cursor: not-allowed;

        &:hover {
            transform: scale(1);
        }
    }

    // box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
}

.library-grid {
    padding: 24px;
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    row-gap: 40px;
    width: 100%;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.delete-icon:hover {
    background: #eeeff0;
}

.delete-icon {
    width: 22px;
}

.x-icon {
    border-radius: 50%;
    font-size: 22px;
    padding: 5px;
}

.nav-links img {
    margin-right: 12px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

.fadedCards {
    background: rgb(244, 247, 254);
    background: linear-gradient(0deg, rgba(244, 247, 254, 1) 37%, rgba(255, 255, 255, 0) 100%);
    z-index: 5;
}

.searchbar {
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
    /* From https://css.glass */
    background-color: rgba(255, 255, 255, 0.37);
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.7px);
    -webkit-backdrop-filter: blur(11.7px);
}

.categories {
    gap: 8px;
}

.statusCard {
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    margin-right: 5px;
    cursor: pointer;
    // border: 0.5px solid #0f172a;
    transition: all 0.3s ease;
    background-color: #2196f3;
    color: #fff;
    border: 1px solid #2196f3;

    &:hover {
        background-color: #fff;
        color: #0f172a;
        border: 1px solid #0f172a;
    }
}

@media (max-width: 768px) {
    ::-webkit-scrollbar {
        display: none;
    }
}

.checkBox:checked {
    background: #1677ff;
    border-color: transparent;
}

/* Slow Spin */
@keyframes spin-slow {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.animate-spin-slow {
    animation: spin-slow 5s linear infinite;
}
</style>
